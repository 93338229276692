import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { misc } from '../../styles/utilities/settings';
import Loader from './Loader';

const YouTubeVideo = ({ title, videoUrl }) => {
  const [started, setStarted] = useState(false);

  return (
    <SYouTubeVideo>
      {!started && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div className={`videoPlayer${started ? ' active' : ' loading'}`}>
        <ReactPlayer
          url={videoUrl}
          loop
          playing
          value={0}
          title={title || '...'}
          muted={false}
          controls
          height="100%"
          width="100%"
          onStart={() => {
            setStarted(true);
          }}
        />
      </div>
    </SYouTubeVideo>
  );
};

export default YouTubeVideo;

export const SYouTubeVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  .videoPlayer {
    transition-duration: ${misc.animSpeed};
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
