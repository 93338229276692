import React, { useContext } from 'react';
import styled from 'styled-components';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';
import { colors } from '../../styles/utilities/settings';
import { SiteContext } from '../Layout';

const Loader = ({ bg }) => {
  const { mainLogo } = useContext(SiteContext);
  return (
    <SLoader bg={bg}>
      <img
        src={imageUrlFor(buildImageObj(mainLogo))}
        alt="Keep Rolling Campaign"
      />
    </SLoader>
  );
};

export default Loader;

export const SLoader = styled.div`
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  img {
    width: 200px;
    height: 200px;
    animation: spin 10s linear infinite;
  }

  &:before {
    ${({ bg }) => bg && `background-color: ${colors.blackOverlay};`}
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.96;
  }
`;
